// import ApiService from "@/core/services/ApiService";
// import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

interface UploadedFile {
  name: string;
  src: string;
  file: File;
  uuid: string;
  coverImage?: { file: Blob; uuid: string };
}

interface Media {
  url: string;
  uuid: string;
  name: string;
  type: string;
  id: number;
  tags: [];
}

@Module
export default class MediaModule extends VuexModule {
  media: Media[] = [];
  uploadedFilesView: UploadedFile[] = [];
  selectedFiles: Media[] = [];
  page = 1;
  progress = 0;

  get uploadedFiles() {
    return this.uploadedFilesView;
  }

  get getMedia() {
    return this.media;
  }

  get getSelectedFiles() {
    return this.selectedFiles;
  }

  get getProgress() {
    return this.progress;
  }

  @Mutation
  [Mutations.SET_PROGRESS](progress) {
    this.progress = progress;
  }

  @Mutation
  [Mutations.SET_MEDIA](files) {
    this.media = [...this.media, ...files];
  }

  @Mutation
  [Mutations.CLEAR_MEDIA]() {
    this.media = [];
  }

  @Mutation
  [Mutations.SET_UPLOADED_FILES](files) {
    this.uploadedFilesView.push(...files);
  }

  @Mutation
  [Mutations.CLEAR_UPLOADED_FILES]() {
    this.uploadedFilesView = [];
  }

  @Mutation
  [Mutations.REMOVE_UPLOADED_FILE](uuid) {
    this.uploadedFilesView = this.uploadedFilesView.filter(
      (file) => file.uuid !== uuid
    );
  }

  @Mutation
  [Mutations.CHANGE_UPLOADED_FILE_NAME]({ uuid, newName }) {
    const file = this.uploadedFilesView.find((file) => file.uuid === uuid);
    if (file) {
      file.name = newName;
    }
  }

  @Mutation
  [Mutations.ADD_SELECTED_FILE](file) {
    this.selectedFiles = [...this.selectedFiles, file];
  }

  @Mutation
  [Mutations.REMOVE_SELECTED_FILE](uuid) {
    this.selectedFiles = this.selectedFiles.filter((e) => e.uuid !== uuid);
  }

  @Mutation
  [Mutations.CLEAR_SELECTED_FILES]() {
    this.selectedFiles = [];
  }

  @Mutation
  [Mutations.REMOVE_MEDIA_FILE](uuid) {
    this.media = this.media.filter((e) => e.uuid !== uuid);
  }

  @Mutation
  [Mutations.RESET_MEDIA_PAGE]() {
    this.page = 1;
  }

  @Mutation
  [Mutations.NEXT_MEDIA_PAGE_MUTATION]() {
    this.page = this.page + 1;
  }

  @Action
  [Actions.SAVE_UPLOADED_FILES](waterMark = false) {
    const formData = new FormData();

    this.uploadedFilesView.forEach((ele) => {
      formData.append(ele.uuid, ele.file);
      if (ele.coverImage) {
        formData.append(ele.coverImage.uuid, ele.coverImage.file);
      }
    });

    const data = this.uploadedFilesView.reduce((ac, cv) => {
      return {
        ...ac,
        [cv.uuid]: {
          name: cv.name,
          coverImage: cv.coverImage?.uuid,
        },
      };
    }, {});

    formData.append("data", JSON.stringify(data));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formData.append("watermark", waterMark);

    return ApiService.uploadFiles("/media", formData, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        this.context.commit(Mutations.SET_PROGRESS, percentCompleted);
      },
    }).then(() => {
      this.context.commit(Mutations.SET_PROGRESS, 0);
      this.context.commit(Mutations.CLEAR_UPLOADED_FILES);
      this.context.commit(Mutations.CLEAR_MEDIA);
      return this.context.dispatch(Actions.GET_MEDIA_FROM_DB, {});
    });
  }

  @Action
  [Actions.GET_MEDIA_FROM_DB]({ name, type }) {
    this.context.commit(Mutations.RESET_MEDIA_PAGE);
    return ApiService.query("/media", {
      params: { name, type },
    }).then(({ data }) => {
      this.context.commit(
        Mutations.SET_MEDIA,
        data.data.map((ele) => ({
          url: process.env.VUE_APP_API_MAIN_URL + ele.path,
          uuid: ele.uuid,
          name: ele.description,
          type: ele.media_type,
          id: ele.id,
          tags: ele.tags,
          coverImage: ele.cover_image
            ? process.env.VUE_APP_API_MAIN_URL + ele.cover_image.path
            : "",
        }))
      );
    });
  }

  @Action
  [Actions.DELETE_MEDIA](uuid) {
    return ApiService.delete(`/media/${uuid}`).then(({ data }) => {
      this.context.commit(Mutations.REMOVE_MEDIA_FILE, data.data.uuid);
    });
  }

  @Action
  [Actions.NEXT_MEDIA_PAGE_ACTION]({ name, type }) {
    this.context.commit(Mutations.NEXT_MEDIA_PAGE_MUTATION);
    console.log(this.page);
    return ApiService.query("/media", {
      params: { name, type, page: this.page },
    }).then(({ data }) => {
      this.context.commit(
        Mutations.SET_MEDIA,
        data.data.map((ele) => ({
          url: process.env.VUE_APP_API_MAIN_URL + ele.path,
          uuid: ele.uuid,
          name: ele.description,
          type: ele.media_type,
          id: ele.id,
          tags: ele.tags,
          coverImage: ele.cover_image
            ? process.env.VUE_APP_API_MAIN_URL + ele.cover_image.path
            : "",
        }))
      );
    });
  }

  @Action
  [Actions.UPDATE_MEDIA]({ uuid, description, tags }) {
    return ApiService.put(`/media/${uuid}`, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      description,
      tags,
    });
    // this.context.commit(Mutations.CLEAR_MEDIA);
    // await this.context.dispatch(Actions.GET_MEDIA_FROM_DB);
  }
}
