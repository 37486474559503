enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  USERS = "users",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",

  // media actions
  SAVE_UPLOADED_FILES = "saveUpLoadedFiles",
  GET_MEDIA_FROM_DB = "getMediaFormDB",
  DELETE_MEDIA = "deleteMedia",
  UPDATE_MEDIA = "updateMedia",
  NEXT_MEDIA_PAGE_ACTION = "nextMediaPageAction",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",

  // media mutations
  UPLOAD_FILE = "uploadFile",
  SET_UPLOADED_FILES = "setUploadedFiles",
  REMOVE_UPLOADED_FILE = "removeUploadedFile",
  CHANGE_UPLOADED_FILE_NAME = "changeUploadedFileName",
  CLEAR_UPLOADED_FILES = "clearUploadedFiles",
  SET_MEDIA = "setMedia",
  CLEAR_MEDIA = "clearMedia",
  ADD_SELECTED_FILE = "addSelectedFile",
  REMOVE_SELECTED_FILE = "removeSelectedFile",
  CLEAR_SELECTED_FILES = "clearSelectedFiles",
  REMOVE_MEDIA_FILE = "removeMediaFile",
  RESET_MEDIA_PAGE = "resetMediaPage",
  NEXT_MEDIA_PAGE_MUTATION = "nextMediaPageMutation",
  SET_PROGRESS = "setProgress",
}

export { Actions, Mutations };
