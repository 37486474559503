enum Permissions {
  DASHBOARD = 1,
  NEWS = 2,
  STORE_NEWS = 3,
  INDEX_NEWS = 4,
  SHOW_NEWS = 5,
  DELETE_NEWS = 6,
  CHANGE_STATUS_NEWS = 7,
  UPDATE_NEWS = 8,
  TRASH_NEWS = 9,
  RESTORE_NEWS = 10,
  ORDER_NEWS = 11,
  NOTIFICATIONS = 12,
  STORE_NOTIFICATIONS = 13,
  INDEX_NOTIFICATIONS = 14,
  DELETE_NOTIFICATIONS = 15,
  CHANGE_STATUS_NOTIFICATIONS = 16,
  SHOW_NOTIFICATIONS = 17,
  UPDATE_NOTIFICATIONS = 18,
  TRASH_NOTIFICATIONS = 19,
  RESTORE_NOTIFICATIONS = 20,
  RECEIVED_NEWS = 21,
  INDEX_RECEIVED_NEWS = 22,
  MOVE_TO_BREAKING_NEWS_RECEIVED_NEWS = 23,
  DELETE_RECEIVED_NEWS = 24,
  SPECIAL_FILES = 25,
  STORE_SPECIAL_FILE = 26,
  INDEX_SPECIAL_FILE = 27,
  DELETE_SPECIAL_FILE = 28,
  CHANGE_STATUS_SPECIAL_FILE = 29,
  COMMENTS_SPACIAL_FILE = 30,
  UPDATE_SPECIAL_FILE = 31,
  TRASH_SPECIAL_FILE = 32,
  RESTORE_SPECIAL_FILE = 33,
  SHOW_SPACIAL_FILE = 34,
  MANAGE_SPECIAL_FILE_COMMENTS = 35,
  DELETE_COMMENT_SPECIAL_FILE = 36,
  INDEX_COMMENT_SPECIAL_FILE = 37,
  TRASH_COMMENT_SPECIAL_FILE = 38,
  RESTORE_COMMENT_SPECIAL_FILE = 39,
  CHANGE_STATUS_COMMENT_SPECIAL_FILE = 40,
  VISITORS = 41,
  STORE_VISITOR = 42,
  INDEX_VISITOR = 43,
  DELETE_VISITOR = 44,
  CHANGE_STATUS_VISITOR = 45,
  SHOW_VISITOR = 46,
  UPDATE_VISITOR = 47,
  TRASH_VISITOR = 48,
  RESTORE_VISITOR = 49,
  FORCEDELETE_VISITOR = 50,
  WRITERS = 51,
  STORE_WRITER = 52,
  INDEX_WRITER = 53,
  DELETE_WRITER = 54,
  CHANGE_STATUS_WRITER = 55,
  SHOW_WRITER = 56,
  UPDATE_WRITER = 57,
  TRASH_WRITER = 58,
  RESTORE_WRITER = 59,
  FORCE_DELETE_WRITER = 60,
  CARICATURES = 61,
  STORE_CARICATURE = 62,
  INDEX_CARICATURE = 63,
  DELETE_CARICATURE = 64,
  CHANGE_STATUS_CARICATURE = 65,
  SHOW_CARICATURE = 66,
  UPDATE_CARICATURE = 67,
  TRASH_CARICATURE = 68,
  RESTORE_CARICATURE = 69,
  FILES_MANAGER = 70,
  STORE_FILES_MANAGER = 71,
  INDEX_FILES_MANAGER = 72,
  DELETE_FILES_MANAGER = 73,
  SHOW_FILES_MANAGER = 74,
  UPDATE_FILES_MANAGER = 75,
  TRASH_FILES_MANAGER = 76,
  RESTORE_FILES_MANAGER = 77,
  PHOTO_REPORTS = 78,
  STORE_PHOTO_REPORT = 79,
  INDEX_PHOTO_REPORT = 80,
  DELETE_PHOTO_REPORT = 81,
  SHOW_PHOTO_REPORT = 82,
  UPDATE_PHOTO_REPORT = 83,
  CHANGE_STATUS_PHOTO_REPORT = 84,
  SHOW_REPORT_IMAGE_PHOTO_REPORT = 85,
  SHOW_REPORT_VIDEO_PHOTO_REPORT = 86,
  TRASH_PHOTO_REPORT = 87,
  RESTORE_PHOTO_REPORT = 88,
  ADS = 89,
  STORE_ADS = 90,
  INDEX_ADS = 91,
  DELETE_ADS = 92,
  SHOW_ADS = 93,
  UPDATE_ADS = 94,
  CHANGE_STATUS_ADS = 95,
  SHOW_ADS_IMAGE_ADS = 96,
  TRASH_ADS = 97,
  RESTORE_ADS = 98,
  VIDEO_ALBUMS = 99,
  STORE_VIDEOS_ALBUM = 100,
  INDEX_VIDEOS_ALBUM = 101,
  DELETE_VIDEOS_ALBUM = 102,
  SHOW_VIDEOS_ALBUM = 103,
  UPDATE_VIDEOS_ALBUM = 104,
  CHANGE_STATUS_VIDEOS_ALBUM = 105,
  SHOW_COVER_IMAGE_VIDEOS_ALBUM = 106,
  SHOW_VIDEOS_ALBUM_VIDEOS_ALBUM = 107,
  TRASH_VIDEOS_ALBUM = 108,
  RESTORE_VIDEOS_ALBUM = 109,
  VIDEO_ALBUMS_MANGE = 110,
  STORE_VIDEO_ALBUM_MANAGEMENT = 111,
  INDEX_VIDEO_ALBUM_MANAGEMENT = 112,
  CHANGE_STATUS_VIDEO_ALBUM_MANAGEMENT = 113,
  RESTORE_VIDEO_ALBUM_MANAGEMENT = 114,
  IMAGES_ALBUMS = 115,
  STORE_IMAGES_ALBUM = 116,
  INDEX_IMAGES_ALBUM = 117,
  DELETE_IMAGES_ALBUM = 118,
  SHOW_IMAGES_ALBUM = 119,
  UPDATE_IMAGES_ALBUM = 120,
  CHANGE_STATUS_IMAGES_ALBUM = 121,
  SHOW_COVER_IMAGE_IMAGES_ALBUM = 122,
  SHOW_VIDEOS_ALBUM_IMAGES_ALBUM = 123,
  TRASH_IMAGES_ALBUM = 124,
  RESTORE_IMAGES_ALBUM = 125,
  IMAGES_ALBUMS_MANAGE = 126,
  STORE_IMAGES_ALBUM_MANAGEMENT = 127,
  INDEX_IMAGES_ALBUM_MANAGEMENT = 128,
  CHANGE_STATUS_IMAGES_ALBUM_MANAGEMENT = 129,
  RESTORE_IMAGES_ALBUM_MANAGEMENT = 130,
  STATIC_CONTENT_PAGES = 131,
  STORE_STATIC_CONTENT_PAGE = 132,
  INDEX_STATIC_CONTENT_PAGE = 133,
  SHOW_STATIC_CONTENT_PAGE = 134,
  UPDATE_STATIC_CONTENT_PAGE = 135,
  CHANGE_STATUS_STATIC_CONTENT_PAGE = 136,
  DELETE_STATIC_CONTENT_PAGE = 137,
  TRASH_STATIC_CONTENT_PAGE = 138,
  RESTORE_STATIC_CONTENT_PAGE = 139,
  POLLS = 140,
  STORE_POLL = 141,
  INDEX_POLL = 142,
  SHOW_RESULT_POLL = 143,
  DELETE_POLL = 144,
  CHANGE_STATUS_POLL = 145,
  SHOW_POLL = 146,
  UPDATE_POLL = 147,
  TRASH_POLL = 148,
  RESTORE_POLL = 149,
  USERS = 150,
  STORE_USER = 151,
  INDEX_USER = 152,
  DELETE_USER = 153,
  CHANGE_STATUS_USER = 154,
  SHOW_USER = 155,
  UPDATE_USER = 156,
  TRASH_USER = 157,
  RESTORE_USER = 158,
  ROLES = 159,
  STORE_ROLE = 160,
  INDEX_ROLE = 161,
  DELETE_ROLE = 162,
  SHOW_ROLE = 163,
  UPDATE_ROLE = 164,
  CHANGE_STATUS_ROLE = 165,
  GET_PERMISSIONS_VIA_ROLE = 166,
  GET_USERS_VIA_ROLE = 167,
  TRASH_ROLE = 168,
  RESTORE_ROLE = 169,
  REPORTS = 170,
  GET_ADMINS_REPORT = 171,
  PRINT_ADMIN_REPORT = 172,
  GET_CARTOONISTS_REPORT = 173,
  PRINT_CARTOONIST_REPORT = 174,
  GET_PREPARED_PROGRAM_REPORT = 175,
  PRINT_PREPARED_PROGRAM_REPORT = 176,
  GET_VISITORS_REPORT = 177,
  PRINT_VISITOR_REPORT = 178,
  SETTINGS = 179,
  GENERAL_SETTINGS = 180,
  WATER_MARK_POSITION = 181,
  MAIN_NEWS_POSITION = 182,
  MENUS_MANAGE = 183,
  MENU_ELEMENTS_MANAGE = 184,
  MENU_CHANGE_STATUS = 185,
  MENU_MANAGE = 186,
  MENUS_ELEMENTS_MANAGE = 187,
  MENU_CHANGE_NAME = 188,
  MENU_ADD_ELEMENT = 189,
  MENU_ORDER_ELEMENT = 190,
  MENU_EDIT_ELEMENT = 191,
  MENU_DELETE_ELEMENT = 192,
  MENU_CHANGE_STATUS_ELEMENT = 193,
  MAIN_PAGE_MANAGE_SECTIONS = 194,
  SHOW_SECTIONS = 195,
  CHANGE_STATUS_SECTIONS = 196,
  DELETE_SECTION = 197,
  ADD_SECTION = 198,
  EDIT_SECTION = 199,
  UPDATE_SECTION = 200,
  RESTORE_SECTION = 201,
  TRASH_MAIN_PAGE_SECTIONS = 202,
  NOTIFICATIONS_TYPE = 203,
  STORE_NOTIFICATION_TYPE = 204,
  INDEX_NOTIFICATIONS_TYPE = 205,
  DELETE_NOTIFICATION_TYPE = 206,
  CHANGE_STATUS_NOTIFICATION_TYPE = 207,
  SHOW_NOTIFICATION_TYPE = 208,
  UPDATE_NOTIFICATION_TYPE = 209,
  TRASH_NOTIFICATION_TYPE = 210,
  RESTORE_NOTIFICATION_TYPE = 211,
  COUNTRIES = 212,
  STORE_COUNTRY = 213,
  INDEX_COUNTRY = 214,
  DELETE_COUNTRY = 215,
  CHANGE_STATUS_COUNTRY = 216,
  SHOW_COUNTRY = 217,
  UPDATE_COUNTRY = 218,
  TRASH_COUNTRY = 219,
  RESTORE_COUNTRY = 220,
  USERS_JOBS = 221,
  STORE_USER_JOB = 222,
  INDEX_USER_JOB = 223,
  DELETE_USER_JOB = 224,
  CHANGE_STATUS_USER_JOB = 225,
  SHOW_USER_JOB = 226,
  UPDATE_USER_JOB = 227,
  TRASH_USER_JOB = 228,
  RESTORE_USER_JOB = 229,
  TAGS = 230,
  STORE_TAG = 231,
  INDEX_TAG = 232,
  DELETE_TAG = 233,
  CHANGE_STATUS_TAG = 234,
  SHOW_TAG = 235,
  UPDATE_TAG = 236,
  TRASH_TAG = 237,
  RESTORE_TAG = 238,
  NEWS_TYPES = 239,
  STORE_NEWS_TYPE = 240,
  INDEX_NEWS_TYPE = 241,
  DELETE_NEWS_TYPE = 242,
  CHANGE_STATUS_NEWS_TYPE = 243,
  SHOW_NEWS_TYPE = 244,
  UPDATE_NEWS_TYPE = 245,
  TRASH_NEWS_TYPE = 246,
  RESTORE_NEWS_TYPE = 247,
  NEWS_CATEGORIES = 248,
  STORE_NEWS_CATEGORIES = 249,
  INDEX_NEWS_CATEGORIES = 250,
  DELETE_NEWS_CATEGORIES = 251,
  CHANGE_STATUS_NEWS_CATEGORIES = 252,
  SHOW_NEWS_CATEGORIES = 253,
  UPDATE_NEWS_CATEGORIES = 254,
  TRASH_NEWS_CATEGORIES = 255,
  RESTORE_NEWS_CATEGORIES = 256,
  IMAGES_AND_VIDEOS_CATEGORIES = 257,
  STORE_IMAGES_VIDEOS_CATEGORIES = 258,
  INDEX_IMAGES_VIDEOS_CATEGORIES = 259,
  DELETE_IMAGES_VIDEOS_CATEGORIES = 260,
  CHANGE_STATUS_IMAGES_VIDEOS_CATEGORIES = 261,
  SHOW_IMAGES_VIDEOS_CATEGORIES = 262,
  UPDATE_IMAGES_VIDEOS_CATEGORIES = 263,
  TRASH_IMAGES_VIDEOS_CATEGORIES = 264,
  RESTORE_IMAGES_VIDEOS_CATEGORIES = 265,
  TV_PROGRAM_CATEGORIES = 266,
  STORE_TV_PROGRAM_CATEGORIES = 267,
  INDEX_TV_PROGRAM_CATEGORIES = 268,
  DELETE_TV_PROGRAM_CATEGORIES = 269,
  CHANGE_STATUS_TV_PROGRAM_CATEGORIES = 270,
  SHOW_TV_PROGRAM_CATEGORIES = 271,
  UPDATE_TV_PROGRAM_CATEGORIES = 272,
  TRASH_TV_PROGRAM_CATEGORIES = 273,
  RESTORE_TV_PROGRAM_CATEGORIES = 274,
  TV_PROGRAM_TYPES = 275,
  STORE_TV_PROGRAM_TYPE = 276,
  INDEX_TV_PROGRAM_TYPE = 277,
  DELETE_TV_PROGRAM_TYPE = 278,
  CHANGE_STATUS_TV_PROGRAM_TYPE = 279,
  SHOW_TV_PROGRAM_TYPE = 280,
  UPDATE_TV_PROGRAM_TYPE = 281,
  TRASH_TV_PROGRAM_TYPE = 282,
  RESTORE_TV_PROGRAM_TYPE = 283,
  MECHANISMS_GUESTS_IN_PROGRAMS = 284,
  STORE_MECHANISMS_GUESTS_PROGRAM = 285,
  INDEX_MECHANISMS_GUESTS_PROGRAM = 286,
  DELETE_MECHANISMS_GUESTS_PROGRAM = 287,
  CHANGE_STATUS_MECHANISMS_GUESTS_PROGRAM = 288,
  SHOW_MECHANISMS_GUESTS_PROGRAM = 289,
  UPDATE_MECHANISMS_GUESTS_PROGRAM = 290,
  TRASH_MECHANISMS_GUESTS_PROGRAM = 291,
  RESTORE_MECHANISMS_GUESTS_PROGRAM = 292,
  PROGRAMS_CYCLES = 293,
  STORE_PROGRAM_CYCLES = 294,
  INDEX_PROGRAM_CYCLES = 295,
  DELETE_PROGRAM_CYCLES = 296,
  CHANGE_STATUS_PROGRAM_CYCLES = 297,
  SHOW_PROGRAM_CYCLES = 298,
  UPDATE_PROGRAM_CYCLES = 299,
  TRASH_PROGRAM_CYCLES = 300,
  RESTORE_PROGRAM_CYCLES = 301,
  PROGRAM = 302,
  STORE_PROGRAM = 303,
  INDEX_PROGRAM = 304,
  SHOW_PROGRAM = 305,
  UPDATE_PROGRAM = 306,
  CHANGE_STATUS_PROGRAM = 307,
  SHOW_PROGRAM_IMAGE_PROGRAM = 308,
  SHOW_PROGRAM_VIDEO_PROGRAM = 309,
  TRASH_PROGRAM = 310,
  EPISODES = 311,
  STORE_EPISODE = 312,
  INDEX_EPISODE = 313,
  SHOW_EPISODE = 314,
  UPDATE_EPISODE = 315,
  CHANGE_STATUS_EPISODE = 316,
  DELETE_EPISODE = 317,
  SHOW_EPISODE_IMAGE_EPISODE = 318,
  SHOW_VIDEO_IMAGE_EPISODE = 319,
  COMMENTS_MANAGEMENT_EPISODE = 320,
  PRINT_SCRIPT_EPISODE = 321,
  TRASH_EPISODE = 322,
  RESTORE_EPISODE = 323,
  DELETE_EPISODE_COMMENT_EPISODE = 324,
  CHANGE_STATUS_COMMENT_EPISODE = 325,
  RESTORE_EPISODE_COMMENT_EPISODE = 326,
  GET_EPISODE_COMMENT_EPISODE = 327,
  GET_TRASH_EPISODE_COMMENT_EPISODE = 328,
  SPECIAL_COVERINGS = 329,
  STORE_SPECIAL_COVERING = 330,
  INDEX_SPECIAL_COVERINGS = 331,
  DELETE_SPECIAL_COVERING = 332,
  CHANGE_STATUS_SPECIAL_COVERING = 333,
  UPDATE_SPECIAL_COVERING = 334,
  TRASH_SPECIAL_COVERING = 335,
  RESTORE_SPECIAL_COVERING = 336,
  SHOW_SPECIAL_COVERING = 337,
  FREQUENCIES = 338,
  SHOW_ALL_FREQUENCIES = 339,
  EDIT_FREQUENCIES = 340,
  UPDATE_FREQUENCIES = 341,
  DELETE_FREQUENCIES = 342,
  ADD_FREQUENCIES = 343,
  CONTENT_CATEGORIES = 344,
  STORE_CONTENT_CATEGORY = 345,
  INDEX_CONTENT_CATEGORIES = 346,
  DELETE_CONTENT_CATEGORY = 347,
  CHANGE_STATUS_CONTENT_CATEGORY = 348,
  SHOW_CONTENT_CATEGORY = 349,
  UPDATE_CONTENT_CATEGORY = 350,
  TRASH_CONTENT_CATEGORY = 351,
  RESTORE_CONTENT_CATEGORY = 352,
  BROADCAST_SCHEDULE_SETTINGS = 354,
  PROGRAM_PORTAL_SETTINGS = 355,
  BROADCAST_SCHEDULE = 356,
  STORE_BROADCAST_SCHEDULE = 357,
  INDEX_BROADCAST_SCHEDULE = 358,
  DELETE_BROADCAST_SCHEDULE = 359,
  SHOW_BROADCAST_SCHEDULE = 360,
  UPDATE_BROADCAST_SCHEDULE = 361,
  RESTORE_BROADCAST_SCHEDULE = 362,
  DELETE_PROGRAM = 363,
  RESTORE_PROGRAM = 364,
  DELETE_VIDEO_ALBUM_MANAGEMENT = 365,
  UPDATE_VIDEO_ALBUM_MANAGEMENT = 366,
  DELETE_IMAGES_ALBUM_MANAGEMENT = 367,
  UPDATE_IMAGES_ALBUM_MANAGEMENT = 368,
  INDEX_LIVE_COVERAGE = 370,
  STORE_LIVE_COVERAGE = 371,
  DELETE_LIVE_COVERAGE = 372,
  UPDATE_LIVE_COVERAGE = 373,
  TRASH_LIVE_COVERAGE = 374,
  RESTORE_LIVE_COVERAGE = 375,
}

export default Permissions;
